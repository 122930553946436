import React from "react"
import Fonts from "./fonts.js"
import {
  Box,
  Center,
  ChakraProvider,
  Container,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const MenuItem = props => (
  <Link to={props.to} title={props.title}>
    <Text
      mt={{ base: 4, md: 0 }}
      mr={6}
      display="block"
      _hover={{
        color: "gray.600",
      }}
    >
      {props.children}
    </Text>
  </Link>
)

const FooterLink = props => (
  <Link to={props.to} title={props.title}>
    <Text padding="1rem" display="block">
      {props.children}
    </Text>
  </Link>
)

const ExtFooterLink = props => (
  <a href={props.to} title={props.title}>
    <Text padding="1rem" display="block">
      {props.children}
    </Text>
  </a>
)

export default function Layout({ title, showTitle = true, slug, children }) {
  const [show, setShow] = React.useState(false)
  const handleToggle = () => setShow(!show)

  return (
    <ChakraProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Anne Rieck - {title}</title>
        <link rel="canonical" href={'"https://arieck.de/' + slug + '"'} />
        <html lang="de" />
      </Helmet>
      <Fonts />
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1rem"
        color="gray.500"
      >
        <Flex align="center" mr={5}></Flex>

        <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
          <svg
            fill="#718096"
            width="12px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box>

        <Box
          display={[show ? "block" : "none", show ? "block" : "none", "flex"]}
          width={["full", "full", "auto"]}
          alignItems="center"
          flexGrow={1}
        >
          <MenuItem to="/" title="Intro">Intro</MenuItem>
          <MenuItem to="/yoga/" title="Yoga">Yoga</MenuItem>
          <MenuItem to="/dates/" title="Dates">Dates</MenuItem>
          <MenuItem to="/contact/" title="Contact">Contact</MenuItem>
        </Box>
      </Flex>
      <Center color="gray.600">
        <Container maxW="3xl">
          {showTitle && (
            <Heading as="h1" size="lg" mt={2} mb={10} textAlign="center">
              {title}
            </Heading>
          )}
          {children}
        </Container>
      </Center>
      <Center>
        <Flex
          as="footer"
          justify="space-between"
          color="gray.500"
          padding="1rem"
          fontSize="sm"
        >
          <ExtFooterLink to="https://www.instagram.com/yogaannesoleil/" title="(&#064;yogaannesoleil) • Instagram-Fotos und -Videos">
            Instagram
          </ExtFooterLink>
          <FooterLink to="/imprint/" title="Impressum">Impressum</FooterLink>
          <FooterLink to="/references/" title="Zertifizierungen">Zertifizierungen</FooterLink>
        </Flex>
      </Center>
    </ChakraProvider>
  )
}
