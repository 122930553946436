import React from "react"
import { Global } from "@emotion/react"
import cyrillicExtItalic400 from "../../static/vEFX2_5QCwIS4_Dhez5jcWBrf0I81-qe.woff2"
import cyrillicItalic400 from "../../static/vEFX2_5QCwIS4_Dhez5jcWBrf0s81-qe.woff2"
import vietnameseItalic400 from "../../static/vEFX2_5QCwIS4_Dhez5jcWBrf0A81-qe.woff2"
import latinItalic400 from "../../static/vEFX2_5QCwIS4_Dhez5jcWBrf0881w.woff2"
import cyrillicExtItalic700 from "../../static/vEFK2_5QCwIS4_Dhez5jcWBrd_QZwti_Wo7H.woff2"
import cyrillicItalic700 from "../../static/vEFK2_5QCwIS4_Dhez5jcWBrd_QZwtG_Wo7H.woff2"
import vietnameseItalic700 from "../../static/vEFK2_5QCwIS4_Dhez5jcWBrd_QZwtq_Wo7H.woff2"
import latinItalic700 from "../../static/vEFK2_5QCwIS4_Dhez5jcWBrd_QZwtW_Wg.woff2"
import cyrillicExt400 from "../../static/vEFV2_5QCwIS4_Dhez5jcWBjT00k0w.woff2"
import cyrillic400 from "../../static/vEFV2_5QCwIS4_Dhez5jcWBqT00k0w.woff2"
import vietnamese400 from "../../static/vEFV2_5QCwIS4_Dhez5jcWBhT00k0w.woff2"
import latin400 from "../../static/vEFV2_5QCwIS4_Dhez5jcWBuT00.woff2"
import cyrillicExt700 from "../../static/vEFI2_5QCwIS4_Dhez5jcWjValgW8tenXg.woff2"
import cyrillic700 from "../../static/vEFI2_5QCwIS4_Dhez5jcWjValgf8tenXg.woff2"
import vietnamese700 from "../../static/vEFI2_5QCwIS4_Dhez5jcWjValgU8tenXg.woff2"
import latin700 from "../../static/vEFI2_5QCwIS4_Dhez5jcWjValgb8tc.woff2"

const Fonts = () => (
  <Global
    styles={`
      // Copied from https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap
      /* cyrillic-ext */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${cyrillicExtItalic400}) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${cyrillicItalic400}) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${vietnameseItalic400}) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 400;
        font-display: swap;
        src: url(${latinItalic400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(${cyrillicExtItalic700}) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(${cyrillicItalic700}) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(${vietnameseItalic700}) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin */
      @font-face {
        font-family: 'Philosopher';
        font-style: italic;
        font-weight: 700;
        font-display: swap;
        src: url(${latinItalic700}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${cyrillicExt400}) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${cyrillic400}) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${vietnamese400}) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${latin400}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* cyrillic-ext */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${cyrillicExt700}) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
      }
      /* cyrillic */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${cyrillic700}) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
      }
      /* vietnamese */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${vietnamese700}) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
      }
      /* latin */
      @font-face {
        font-family: 'Philosopher';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(${latin700}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
)

export default Fonts
